import React, { Component } from 'react';
import './App.css';

import logo from './img/dot-logo-fc.svg';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div>
            <img src={logo} height="400px" style={{marginTop: "150px"}} alt="Devs On Tap" />
        </div>
        <div style={{textAlign: "center", fontFamily: "Avenir", fontSize: "30px", marginTop: 20}}>
            Site coming soon...
        </div>
      </div>
    );
  }
}

export default App;
